var PostMessageNamespace = "360player";

/**
 Send messages to an iframe
 */
var sendMessage = function(contentWindow, action, data) {
    contentWindow.postMessage({
        namespace: PostMessageNamespace,
        action: action,
        data: data
    }, '*')
}

/**
 * Check post message namespace
 */
var validatePostMessageNamespace = function(message) {
    return message.namespace === PostMessageNamespace;
}


export {
    sendMessage,
    validatePostMessageNamespace
}
