import { sendMessage, validatePostMessageNamespace } from './src/player/api.js'
import './src/polyfills/string.includes.js'
import './src/polyfills/element.dataset.js'

var namespace = window.AtlasConf || {};
window.AtlasConf = namespace;

(function() {
    // lock embed script initialization to one instance per namespace
    if (namespace.embedScriptIntializedLock === true) {
        console.warn("Initalized the embed.js script more than once! Please remove extra references.");
        return;
    }
    namespace.embedScriptIntializedLock = true;

    // run config on various 
    var registerPlayer = function(el, token) {
        sendMessage(el.contentWindow, 'registerPlayer', {token: token})
    }

    var findIframeForToken = function(token) {
        var iframes = document.querySelectorAll('iframe[data-token]')
        for (var idx=0; idx < iframes.length; idx++) {
            var el = iframes[idx];

            // Detect with the proper data-token property
            if (el.dataset.token === token) {
                return el;
            }

            // Some users will forget to use the data-token property
            if (el.src.includes('/' + token + '/')) {
                return el;
            }
        }
    }

    // fake fullscreen mode
    var isFullscreen = function(iframeElement) {
        return iframeElement.dataset.fullscreen === 'true'
    }

    var adjustFakeFullScreen = function(iframeElement) {
        if (isFullscreen(iframeElement)) {
            // if already in fullscreen, then we adjust
            setFullScreen(iframeElement, true)
        }
    }

    var toggleFakeFullScreen = function(iframeElement) {
        var goFullscreen = !isFullscreen(iframeElement)
        setFullScreen(iframeElement, goFullscreen)
    }

    var setFullScreen = function(iframeElement, goFullscreen) {
        var FullscreenStyles = {
            window: [
                'height: 100% !important',
                'overflow: hidden !important',
                'margin: 0px !important',
                'padding: 0px !important',
                'z-index: 2147483100 !important',
            ],
            iframe: [
                'overflow: visible !important',
                'max-width: none !important',
                'max-height: none !important',
                'position: fixed !important',
                'margin: 0px !important',
                'padding: 0px !important',
                'top: 0px !important',
                'left: 0px !important',
                'bottom: 0px !important',
                'right: 0px !important',
                'z-index: 2147483647 !important',  // highest int value (also a Mersenne number)
                'width: ' + window.innerWidth + 'px !important',
                'height: ' + window.innerHeight + 'px !important'
            ]
        }

        var iframeStyles, bodyStyles, htmlStyles;
        if (goFullscreen) {
            iframeStyles = FullscreenStyles.iframe.join(';')
            bodyStyles = FullscreenStyles.window.join(';')
            htmlStyles = FullscreenStyles.window.join(';')
        } else {
            iframeStyles = bodyStyles = htmlStyles = ''
        }
        iframeElement.dataset.fullscreen = goFullscreen ? 'true' : 'false'
        iframeElement.setAttribute('style', iframeStyles)
        var iframeDoc = iframeElement.ownerDocument
        iframeDoc.body.setAttribute('style', bodyStyles)
        iframeDoc.documentElement.setAttribute('style', htmlStyles)
    }

    // bind to postMessage messages (before the DOM is ready to avoid missing a message)
    window.addEventListener("message", function(evt) {
        var message = evt.data;

        if (!validatePostMessageNamespace(message)) {
            // discard other services messages
            return;
        }

        if (message.action === 'playerInitialized') {
            var token = message.data.token;
            var iframeElement = findIframeForToken(token)
            registerPlayer(iframeElement, token);
        }
        else if (message.action === 'toggleFakeFullScreen') {
            var token = message.data.token
            var iframeElement = findIframeForToken(token)
            if (!iframeElement) {
                throw new Error("No player registered for the token: " + token);
            }
            toggleFakeFullScreen(iframeElement)

            if (isFullscreen(iframeElement)) {
                sendMessage(iframeElement.contentWindow, 'enteredFullscreen', {token: token})
            } else {
                sendMessage(iframeElement.contentWindow, 'leftFullscreen', {token: token})
            }
        }
        else if (message.action === 'adjustFakeFullScreen') {
            var token = message.data.token
            var iframeElement = findIframeForToken(token)
            if (!iframeElement) {
                throw new Error("No player registered for the token: " + token);
            }
            adjustFakeFullScreen(iframeElement)
        }
        else {
            throw new Error("Unknown action for postMessage: " + message.action);
        }
    }, false);

})();
